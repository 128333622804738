<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 40px; line-height: 40px">
      <span
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          opacity: 0.5;
          margin-right: 13px;
          cursor: pointer;
        "
        @click="toSearch"
        >物实例/</span
      >
      <span style="font-size: 14px; font-weight: 400; color: #082254"
        >物实例详情</span
      >
    </div>

    <div
      style="
        width: 100%;
        height: calc(100% - 40px);
        background: transparent;
        position: relative;
      "
    >
      <div
        style="
          width: 100%;
          height: 226px;
          margin-bottom: 20px;
          background: white;
          padding: 20px;
        "
      >
        <div
          style="wdith: 100%; font-size: 16px; font-weight: 600; color: #082254"
        >
          {{ detailData.instanceName }}
        </div>

        <div
          style="
            wdith: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
          "
        >
          <div
            style="width: 500px; flex: none; font-size: 16px; color: #082254"
          >
            物实例编码：{{ detailData.instanceId }}
          </div>
          <div
            style="width: 500px; flex: none; font-size: 16px; color: #082254"
          >
            直采点位数：{{ detailData.dirGatherPointNum }}
          </div>
          <div style="flex: 1; font-size: 16px; color: #082254">
            备注：{{ detailData.memo ? detailData.memo : "--" }}
          </div>
        </div>

        <div
          style="
            wdith: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
          "
        >
          <div
            style="width: 500px; flex: none; font-size: 16px; color: #082254"
          >
            类型：{{
              detailData.instanceTypeName ? detailData.instanceTypeName : "--"
            }}
          </div>
          <div
            style="width: 500px; flex: none; font-size: 16px; color: #082254"
          >
            通信成功/测点数：{{ detailData.communicateSuccessNum }}/{{
              detailData.pointNum
            }}
          </div>
          <div style="flex: 1; font-size: 16px; color: #082254">
            创建人：{{
              detailData.createUserName ? detailData.createUserName : "--"
            }}
          </div>
        </div>

        <div
          style="
            wdith: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
          "
        >
          <div
            style="width: 500px; flex: none; font-size: 16px; color: #082254"
          >
            计算点位数：{{ detailData.calcPointNum }}
          </div>
          <div
            style="width: 500px; flex: none; font-size: 16px; color: #082254"
          >
            是否发布：{{ detailData.released ? "是" : "否" }}
          </div>
          <div style="flex: 1; font-size: 16px; color: #082254">
            创建时间：{{ detailData.createTime | formateV }}
          </div>
        </div>

        <div
          style="
            wdith: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
          "
        >
          <div
            style="width: 500px; flex: none; font-size: 16px; color: #082254"
          >
            是否虚拟节点：{{ detailData.faked ? "是" : "否" }}
          </div>
        </div>
      </div>

      <div
        style="
          width: 100%;
          height: calc(100% - 246px);
          background: white;
          padding: 20px;
          overflow:auto;
        "
      >
        <el-table
          :data="detailData.instanceAttrList"
          style="width: 100%; overflow: auto; margin-top: 10px"
          :border="isborder1"
          :row-class-name="tableRowClassName"
          v-loading="loading"
        >
          <el-table-column
            prop="attrName"
            label="属性名称"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="attrId"
            label="属性编码"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="unit"
            label="数据单位"
            width="100"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.unit">
                {{ scope.row.unit }}
              </template>
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column
            prop="energyTypeId"
            label="能源类型"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="dataSourceType"
            label="测点类型"
            width="100"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.dataSourceType === 'ANALOG'">
                模拟量
              </template>
              <template v-else-if="scope.row.dataSourceType === 'DIGITAL'">
                数字量
              </template>
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="templateName"
            label="数据来源"
            width="100"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.dataResource === 'DIRECT_GATHER'">
                直接采集
              </template>
              <template v-else-if="scope.row.dataResource === 'CALC'">
                计算量
              </template>
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="templateName"
            label="数据类型"
            width="100"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.dataType === 'INSTANT'">
                瞬时值
              </template>
              <template v-else-if="scope.row.dataType === 'ACCUM'">
                累计值
              </template>
              <template v-else>--</template>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="templateName"
            label="数据形式"
            width="150"
          ></el-table-column> -->

          <el-table-column
            align="center"
            prop="dataSource"
            label="数据源"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <template v-if="scope.row.dataSource">
                {{ scope.row.dataSource }}
              </template>
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="privilege"
            label="读写设置"
            width="100"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.privilege">
                <template v-if="scope.row.privilege === 'R'"> 只读 </template>
                <template v-else>读写</template>
              </template>
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="templateName"
            label="数据阈值"
            width="150"
          >
            <template slot-scope="scope">
              <!-- <template v-if="scope.row.minDisplay">
                {{ scope.row.dataSource }}
              </template>
              <template v-else>--</template> -->
              {{ scope.row.minDisplay }}~{{ scope.row.maxDisplay }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="defaultAttr"
            label="默认属性"
            width="80"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.defaultAttr"> 是 </template>
              <template v-else>否</template>
            </template>
          </el-table-column>

          <el-table-column
            prop="communicateStatus"
            label="通信状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.communicateStatus">
                <span style="color: #02b84b"
                  ><i class="el-icon-success"></i></span
                ><span> 成功</span>
              </template>
              <template v-else
                ><span style="color: #e52323"
                  ><i class="el-icon-error"></i></span
                ><span> 失败</span></template
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="templateName"
            label="备注"
            width="150"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.memo">
                {{ scope.row.memo }}
              </template>
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <span
                style="color: #3f6df8; margin-right: 5px; cursor: pointer"
                @click="showUpdate(scope.row)"
                v-if="!scope.row.released"
              >
                修改
              </span>
              <span
                style="color: #e52323; margin-right: 5px; cursor: pointer"
                @click="deleteReport(scope.row)"
              >
                删除
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      :title="userSettingTitle"
      :visible.sync="changePasswordVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item
          label="属性名称："
          prop="projectId"
          style="position: relative; margin-bottom: 20px"
        >
          <el-cascader
            :disabled="ruleForm.defaultAttr"
            :props="props"
            v-model="ruleForm.attrId"
            @change="changeCas($event)"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="属性编码："
          prop="reportName"
          style="position: relative"
          class="inline-input"
        >
          <div style="width: 100%">
            {{ ruleForm.attrId[1] }}
          </div>
        </el-form-item>
        <el-form-item label="数据源：" prop="memo" style="position: relative">
          <el-input v-model="ruleForm.dataSource"></el-input>
        </el-form-item>

        <el-form-item
          label="单位："
          prop="reportName"
          style="position: relative"
        >
          <div style="width: 200px">
            <!-- {{ ruleForm.unit }} -->
            <el-input v-model="ruleForm.unit"></el-input>
          </div>
        </el-form-item>

        <el-form-item
          label="测点类型："
          prop="reportName"
          style="position: relative"
          class="inline-input"
        >
          <div style="width: 200px">
            <el-radio-group
              v-model="ruleForm.dataSourceType"
              :disabled="ruleForm.defaultAttr"
            >
              <el-radio
                v-for="(item, index) in attr1"
                :label="item.attrId"
                :key="index"
                >{{ item.attrName }}</el-radio
              >
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item
          label="数据来源："
          prop="reportName"
          style="position: relative"
          class="inline-input"
        >
          <div style="width: 200px">
            <el-radio-group
              v-model="ruleForm.dataResource"
              :disabled="ruleForm.defaultAttr"
            >
              <el-radio
                v-for="(item, index) in attr2"
                :label="item.attrId"
                :key="index"
                >{{ item.attrName }}</el-radio
              >
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item
          label="数据类型："
          prop="reportName"
          style="position: relative"
          class="inline-input"
        >
          <div style="width: 200px">
            <el-radio-group
              v-model="ruleForm.dataType"
              :disabled="ruleForm.defaultAttr"
            >
              <el-radio
                v-for="(item, index) in attr3"
                :label="item.attrId"
                :key="index"
                >{{ item.attrName }}</el-radio
              >
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item
          label="读写设置："
          prop="reportName"
          style="position: relative"
          class="inline-input"
        >
          <div style="width: 200px">
            <el-radio-group
              v-model="ruleForm.privilege"
              :disabled="ruleForm.defaultAttr"
            >
              <el-radio
                v-for="(item, index) in options1"
                :label="item.code"
                :key="index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </div>
          <!-- <el-select v-model="ruleForm.privilege" placeholder="请选择">
            <el-option
              v-for="item in options1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select> -->
        </el-form-item>

        <el-form-item
          label="数据阈值："
          prop="reportName"
          style="position: relative; margin-bottom: 10px"
          class="small-input"
        >
          <el-input v-model="ruleForm.minDisplay"></el-input>
          <span style="margin-left: 8px; margin-right: 8px">至</span>
          <el-input v-model="ruleForm.maxDisplay"></el-input>
        </el-form-item>

        <el-form-item label="备注：" prop="memo" style="position: relative">
          <el-input v-model="ruleForm.memo"></el-input>
        </el-form-item>

        <el-form-item
          label="默认属性："
          prop="memo"
          style="position: relative"
          class="inline-input"
        >
          <div style="width: 200px">
            <el-switch v-model="ruleForm.defaultAttr" disabled> </el-switch>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addNew">确 定</el-button>
        <el-button @click="changePasswordVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import * as echarts from "echarts";
import {
  getInstanceDetail,
  deleteInstanceAttr,
  getProjectSettingDetail,
  // updateProjectSetting,
  changeProjectSetting,
  // getGroupList,
  updateInstanceAttr,
  getEnergy,
  getEnergyAttr,
} from "@/request/apiLg";
export default {
  filters: {
    formatStatus: function (value) {
      if (value) {
        return "发布";
      } else {
        return "暂停";
      }
    },
    formateV: function (value) {
      if (value) {
        var date1 = new Date(value);
        var YY = date1.getFullYear() + "-";
        var MM =
          (date1.getMonth() + 1 < 10
            ? "0" + (date1.getMonth() + 1)
            : date1.getMonth() + 1) + "-";
        var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
        var hh =
          (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
          ":";
        var mm =
          (date1.getMinutes() < 10
            ? "0" + date1.getMinutes()
            : date1.getMinutes()) + ":";
        var ss =
          date1.getSeconds() < 10
            ? "0" + date1.getSeconds()
            : date1.getSeconds();
        return YY + MM + DD + " " + hh + mm + ss;
      } else {
        return "--";
      }
    },
    formatAType(value) {
      if (value === "BUSINESS") {
        return "商业用户";
      } else if (value === "ENERGY_STATION") {
        return "能源站";
      } else if (value === "INDUSTRIAL") {
        return "工业用户";
      } else if (value === "POWER_STATION") {
        return "光伏电站";
      } else if (value === "STORAGE_POWER_STATION") {
        return "储能电站";
      } else {
        return "--";
      }
    },
  },
  data() {
    return {
      options1: [
        { code: "R", name: "只读" },
        { code: "RW", name: "读写" },
      ],
      editId: "",
      attr1: [
        {
          attrId: "ANALOG",
          attrName: "模拟量",
        },
        {
          attrId: "DIGITAL",
          attrName: "数字量",
        },
      ],
      attr2: [
        {
          attrId: "DIRECT_GATHER",
          attrName: "直接采集",
        },
        {
          attrId: "CALC",
          attrName: "计算量",
        },
      ],
      attr3: [
        {
          attrId: "INSTANT",
          attrName: "瞬时值",
        },
        {
          attrId: "ACCUM",
          attrName: "累计值",
        },
      ],
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          // const { level } = node;
          // setTimeout(() => {
          //   let id = 1;
          //   const nodes = Array.from({ length: level + 1 }).map(() => ({
          //     value: ++id,
          //     label: `选项${id}`,
          //     leaf: level >= 2,
          //   }));
          //   // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          //   resolve(nodes);
          // }, 1000);
          setTimeout(() => {
            if (node.level == 0) {
              getEnergy().then((response) => {
                const cities = response.data.data.map((value) => ({
                  value: value.energyTypeId,
                  label: value.energyTypeName,
                  leaf: node.level >= 1,
                }));
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getEnergyAttr(node.value).then((response) => {
                const areas = response.data.data.map((value) => ({
                  value: value.energyAttrId,
                  label: value.energyAttrName,
                  leaf: node.level >= 1,
                }));
                // this.tempUNIT = response.data.data
                resolve(areas);
              });
            }
          }, 100);
        },
      },
      isborder1: false,
      optionsInstance: [],
      imageUrl: "",
      isView: false,
      updateProjectSetting: "编辑项目",
      changePasswordVisible: false,
      ruleForm: {
        attrId: "",
        privilege: "",
        attrName: "",
        dataResource: "",
        dataSource: "",
        dataSourceType: "",
        dataType: "",
        defaultAttr: false,
        energyTypeId: "",
        maxDisplay: 0,
        memo: "",
        minDisplay: 0,
        unit: "",
      },
      currentName: "",
      tableKey: [],
      tableKey2: [],
      changeWorc: "发布",
      finishTitle: "",
      loading: false,
      active: 2,
      multipleSelection: [],
      tableData: [],
      value1: "",
      isActivenTime: 1,
      detailData: {},
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      userSettingTitle: "编辑项目",
      rules: {},
      optionsType2: [
        {
          value: "BUSINESS",
          label: "商业用户",
        },
        {
          value: "ENERGY_STATION",
          label: "能源站",
        },
        {
          value: "INDUSTRIAL",
          label: "工业用户",
        },
        {
          value: "POWER_STATION",
          label: "光伏电站",
        },
        {
          value: "STORAGE_POWER_STATION",
          label: "储能电站",
        },
      ],
    };
  },
  methods: {
    changeCas(value) {
      console.log(value);
      getEnergyAttr(value[0]).then((response) => {
        response.data.data.forEach((item) => {
          if (item.energyAttrId === value[1]) {
            let temp = "--";
            if (item.unit) {
              temp = item.unit;
            }
            this.ruleForm.unit = temp;
            let temp2 = "--";
            if (item.dataSource) {
              temp2 = item.dataSource;
            }
            this.ruleForm.dataSource = temp2;
            this.ruleForm.attrName = item.energyAttrName;
            this.ruleForm.privilege = item.privilege;
          }
        });
      });
    },
    getOption() {},
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    deleteReport(row) {
      this.$confirm("确认删除？")
        .then(() => {
          // done();
          let params = {
            attrId: row.attrId,
            energyTypeId: row.energyTypeId,
            instanceId: this.$route.query.id,
          };
          deleteInstanceAttr(params).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              getInstanceDetail(this.$route.query.id).then((res2) => {
                console.log(res2);
                this.detailData = res2.data.data;
              });
            }
          });
        })
        .catch(() => {});
    },
    showUpdate(row) {
      this.editId = row.attrId;
      this.userSettingTitle = "编辑属性";
      this.changePasswordVisible = true;
      this.isEdit = true;
      this.isView = false;
      let temp = JSON.parse(JSON.stringify(row));
      temp.attrId = [temp.energyTypeId, temp.attrId];
      if (!temp.unit) {
        temp.unit = "--";
      }
      this.ruleForm = temp;
      // getProjectSettingDetail(id).then((resA) => {
      //   if (resA.data.code === 0) {
      //     // this.$message.success("修改状态成功");
      //     // getInstanceAttr(resA.data.data.instanceId).then((res2) => {
      //     //   // debugger
      //     //   // this.options2[v] = res2.data.data;
      //     //   // console.log("this.options2", this.options2);
      //     //   // let temp = JSON.parse(JSON.stringify(this.options2));
      //     //   // this.options2 = temp;
      //     //   this.optionsInstanceAttr = res2.data.data;
      //     //   this.changePasswordVisible = true;
      //     //   this.isEdit = true;
      //     //   this.isView = false;
      //     //   this.ruleForm = resA.data.data;
      //     // });
      //     this.userSettingTitle = "编辑项目";
      //     this.changePasswordVisible = true;
      //     // this.isEdit = true;
      //     // this.isView = false;
      //     this.ruleForm = resA.data.data;
      //   }
      // });
    },
    changeStatus(id) {
      // debugger
      if (this.detailData.enabled) {
        this.$confirm("确认暂停？")
          .then(() => {
            // done();
            changeProjectSetting(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                // this.searchFor();
                this.changeWorc = "发布";
                getProjectSettingDetail(this.$route.query.id).then((res2) => {
                  // this.loading = false;
                  console.log(res2);
                  this.detailData = res2.data.data;
                  if (this.detailData.enabled) {
                    this.changeWorc = "暂停";
                  } else {
                    this.changeWorc = "发布";
                  }
                  if (!this.detailData.projectDesc) {
                    this.detailData.projectDesc = "--";
                  }
                  if (!this.detailData.projectArea) {
                    this.detailData.projectArea = "--";
                  }
                });
              }
            });
          })
          .catch(() => {});
      } else {
        this.$confirm("确认发布？")
          .then(() => {
            // done();
            changeProjectSetting(id).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("修改状态成功");
                // this.searchFor();
                this.changeWorc = "暂停";
                getProjectSettingDetail(this.$route.query.id).then((res2) => {
                  // this.loading = false;
                  console.log(res2);
                  this.detailData = res2.data.data;
                  if (this.detailData.enabled) {
                    this.changeWorc = "暂停";
                  } else {
                    this.changeWorc = "发布";
                  }
                  if (!this.detailData.projectDesc) {
                    this.detailData.projectDesc = "--";
                  }
                  if (!this.detailData.projectArea) {
                    this.detailData.projectArea = "--";
                  }
                });
              }
            });
          })
          .catch(() => {});
      }
    },
    addNew() {
      // let temp = {
      //   alarmId: "",
      //   alarmLevelId: "",
      //   alarmLevelName: "",
      //   alarmType: "",
      //   attrId: "",
      //   attrName: "",
      //   instanceId: "",
      //   instanceName: "",
      //   maxDisplay: 0,
      //   minDisplay: 0,
      //   periodMinute: "",
      //   released: true,
      // };

      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // alarmSetCheck(this.ruleForm).then((resC) => {
          // if (!resC.data.data.exist) {
          // if (this.ruleForm.pwd) {
          //   delete this.ruleForm.pwd;
          // }
          // let dateT = new Date();
          // this.ruleForm.updateTime = this.formatDate(dateT);
          let temp = JSON.parse(JSON.stringify(this.ruleForm));
          // temp.templateAttrList.forEach((item) => {
          let tempA = JSON.parse(JSON.stringify(this.ruleForm.attrId));
          temp.attrId = tempA[1];
          temp.energyTypeId = tempA[0];
          if (temp.unit === "--") {
            temp.unit = "";
          }
          // });
          let params = {};
          params.vo = temp;
          params.attrId = this.editId;
          params.instanceId = this.$route.query.id;
          updateInstanceAttr(params).then((res) => {
            console.log("res", res);
            if (res.data.code === 0) {
              this.changePasswordVisible = false;
              this.$message.success("编辑成功");
              // this.searchFor();
              getInstanceDetail(this.$route.query.id).then((res2) => {
                console.log(res2);
                this.detailData = res2.data.data;
              });
            }
          });
        }
      });
    },
    toSearch() {
      this.$router.push({ name: "instance" });
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    clickNH(index) {
      this.isActivenTime = index;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  mounted() {
    // getGroupList().then((res) => {
    //   this.optionsInstance = res.data.data;
    //   // getInstanceAttr(this.optionsInstance[0].instanceId).then((res1) => {
    //   //   this.optionsInstanceAttr = res1.data.data;
    //   // });
    // });
    // getAlarmDetail(this.$route.query.id).then((res) => {
    //   console.log("res", res);
    //   this.detailData = res.data.data;
    //   if (this.detailData.alarmStatus === "UNTREATED") {
    //     this.active = 0;
    //     this.finishTitle = "已处理";
    //   } else if (this.detailData.alarmStatus === "PROCESSED") {
    //     this.active = 3;
    //     this.finishTitle = "已处理";
    //   } else if (this.detailData.alarmStatus === "INGORED") {
    //     this.active = 3;
    //     this.finishTitle = "已忽略";
    //   }
    // });
    getInstanceDetail(this.$route.query.id).then((res2) => {
      // this.loading = false;
      console.log(res2);
      this.detailData = res2.data.data;
      // if (this.detailData.enabled) {
      //   this.changeWorc = "暂停";
      // } else {
      //   this.changeWorc = "发布";
      // }
      // if (!this.detailData.projectDesc) {
      //   this.detailData.projectDesc = "--";
      // }
      // if (!this.detailData.projectArea) {
      //   this.detailData.projectArea = "--";
      // }
      // if(!this.detailData.projectDesc){
      //   this.detailData.projectDesc = '--'
      // }
      // this.tableData = res2.data.data.rows;
      // // this.tableKey = res2.data.data.timestamps;
      // let temp = [];
      // res2.data.data.timestamps.forEach((item) => {
      //   temp.push(this.formatDate(item));
      // });
      // this.tableKey = temp;
      // this.tableKey2 = res2.data.data.columns;
    });
  },
};
</script>
<style>
.el-step__head.is-success {
  color: #1a7aff;
  border-color: #1a7aff;
}
.el-step__title.is-success {
  color: #1a7aff;
}
</style>
<style scoped>
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.title1 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #f6b084 0%, #ea9b7a 46%, #e97f75 100%);
  border-radius: 4px;
  position: relative;
}
.title2 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #71a1de 0%, #5a82dd 46%, #516adc 100%);
  border-radius: 4px;
  position: relative;
}
.title3 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #b97df9 0%, #ad6bf1 54%, #8c44e1 100%);
  border-radius: 4px;
  position: relative;
}
.title4 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #6bcf84 0%, #6ec887 46%, #2aab4c 100%);
  border-radius: 4px;
  position: relative;
}

.body1 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.body2 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style scoped>
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
.el-dialog__wrapper >>> input {
  width: 350px !important;
  height: 32px !important;
}

.select >>> input {
  width: 160px !important;
  height: 29px !important;
  /* color: #3f6df8; */
  /* cursor: pointer; */
}
.select >>> .el-input__icon {
  line-height: 29px !important;
}
.isBorder >>> input {
  border-color: #3f6df8;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  color: #082254;
  font-size: 14px;
  cursor: pointer;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.isActive {
  background: #3f6df8;
}
.activeOption2 {
  background: #3f6df8;
  color: white;
}
/* .date >>> input {
  width: 180px !important;
  height: 32px !important;
} */

.date >>> .el-input__icon {
  line-height: 32px !important;
}
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.el-tree >>> .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
.time-square:hover {
  background: #3f6df8 !important;
  color: white !important;
}
.isSelectTime {
  background: #3f6df8 !important;
  color: white !important;
}
.small-s >>> input {
  width: 220px !important;
  height: 32px !important;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding-bottom: 5px !important;
}
.tree-big >>> input {
  width: 750px !important;
}
.inline-input >>> input {
  width: 160px !important;
}
.small-input >>> input {
  width: 160px !important;
}
.small-input >>> .el-input {
  width: 160px !important;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
/* .el-table th > .cell {
  padding-left: 30px;
  padding-right: 30px;
}
.el-table .cell {
  padding-left: 30px;
  padding-right: 30px;
} */
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
/* .is-current > .el-tree-node__content {
  background: #3f6df8 !important;
  color: white !important;
} */
</style>